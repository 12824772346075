<template>
  <div class="h-full w-full bg-primary-50 dark:bg-primary-950">
    <OScroll>
      <div class="mx-auto text-center">
        <div class="p-6 relative">
          <a href="https://ovatu.com" class="mb-32 inline-block">
            <img src="~/assets/images/logo/logo-navy-full.png" class="h-10 opacity-85 dark:invert">
          </a>

          <h1 class="font-display font-bold text-7xl text-primary-700 dark:text-primary-200 leading-none -mb-1">
            Try Ovatu!
          </h1>
          <h1 class="font-display font-bold text-7xl text-primary-700 dark:text-primary-200 leading-none">
            with an instant demo.
          </h1>
          <p class="mb-12 text-primary-600 mt-6 font-display dark:text-primary-300">
            It's faster, more powerful and more intuitive.<br> Try a fully loaded instant demo of Ovatu. No account details or credit card required.
          </p>
        </div>
      </div>

      <div v-if="isApple || isAndroid" class="flex items-center justify-center p-8 space-x-2">
        <a v-if="isApple" href="https://itunes.apple.com/au/app/ovatu-next/id1589890786" target="_blank" class="flex mt-3 w-48 h-14 bg-black text-white rounded-xl items-center justify-center dark:bg-white dark:text-black">
          <div class="mr-3">
            <svg viewBox="0 0 384 512" width="30">
              <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
            </svg>
          </div>
          <div>
            <div class="text-xs">
              Download on the
            </div>
            <div class="text-2xl tracking-tighter font-semibold font-sans -mt-1">
              App Store
            </div>
          </div>
        </a>

        <a v-if="isAndroid" href="https://play.google.com/store/apps/details?id=com.ovatu.next" target="_blank" class="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center dark:bg-white dark:text-black">
          <div class="mr-3">
            <svg viewBox="30 336.7 120.9 129.2" width="30">
              <path fill="#FFD400" d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z" />
              <path fill="#FF3333" d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z" />
              <path fill="#48FF48" d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z" />
              <path fill="#3BCCFF" d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z" />
            </svg>
          </div>
          <div>
            <div class="text-xs">
              GET IT ON
            </div>
            <div class="text-xl tracking-tight font-semibold font-sans -mt-1">
              Google Play
            </div>
          </div>
        </a>
      </div>

      <div v-if="ready && !isApple && !isAndroid && industries" class="max-w-sm mx-auto">
        <template v-if="!form.skipSignUp">
          <div class="mb-3">
            <label class="text-primary-700 dark:text-primary-200 font-display mb-2 text-base">First name</label>
            <input v-model="form.firstName" type="text" class="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-gray-700 shadow-sm">
          </div>

          <div class="mb-3">
            <label class="text-primary-700 dark:text-primary-200 font-display mb-2 text-base">Last name</label>
            <input v-model="form.lastName" type="text" class="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-gray-700 shadow-sm">
          </div>

          <div class="mb-6">
            <label class="text-primary-700 dark:text-primary-200 font-display mb-2 text-base">Email address (required)</label>
            <input v-model="form.email" type="text" class="w-full p-2 bg-gray-50 border border-gray-300 rounded-md text-gray-700 shadow-sm">
          </div>
        </template>

        <label class="text-primary-700 dark:text-primary-200 font-display mb-2 block text-base">Select an industry to generate a live demo (required)</label>
        <div class="mb-6 grid grid-cols-3 gap-3">
          <template v-for="industry in industries">
            <label
              class="border rounded p-3 cursor-pointer bg-white dark:bg-black/20 bg-opacity-25 hover:bg-opacity-50 transition-all hover:scale-102 hover:shadow-sm"
              :class="form.businessType === industry.type ? 'border-primary-500 dark:border-primary-400' : 'border-primary-100 dark:border-primary-950'"
            >
              <input
                :id="industry.type"
                :key="industry.type"
                v-model="form.businessType"
                type="radio"
                :value="industry.type"
                class="hidden"
              >
              <div class="flex flex-col items-center">
                <div class="h-12 w-12 bg-primary-200 bg-opacity-50 rounded-lg mb-3 flex items-center justify-center text-primary-500 dark:text-primary-400 dark:bg-primary-950" v-html="industry.icon" />
                <p class="text-primary-600 font-display font-medium dark:text-primary-300">
                  {{ industry.name }}
                </p>
              </div>
            </label>
          </template>
        </div>

        <OButton
          class="w-full"
          variant="primary"
          size="lg"
          :disabled="!form.businessType || (!form.email && !form.skipSignUp)"
          @click="handleCreateDemo"
        >
          Start demo
        </OButton>
      </div>
    </OScroll>

    <transition name="fade">
      <div v-if="demo" class="fixed flex items-end justify-center inset-0 h-full w-full z-80000 bg-primary-100 p-4 dark:bg-primary-900">
        <div class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div class="text-center font-display">
            <h4 class="text-primary-700 font-black text-5xl mb-4 leading-none dark:text-primary-200">
              Creating <strong>{{ demo.title }}</strong><br>demo account
            </h4>
            <p class="text-primary-600 text-lg dark:text-primary-300">
              {{ status ? status.status.description : 'Building' }}
            </p>
          </div>
        </div>
        <div>
          <img src="~/assets/images/logo/logo-navy-full.png" class="h-10 opacity-75 mx-auto mb-2">
          <p class="text-gray-500 dark:text-gray-400 text-xs text-center">
            © 2023 Ovatu Pty Ltd ABN 92 159 394 484 ATF Ovatu Trading Trust ABN 74 504 757 106 | OVATU is a registered trademark of Ovatu Pty Ltd.
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import * as API from '@/api'
import { OButton } from '~/components/elements'

export default {
  components: {
    OButton
  },
  layout: 'full',
  middleware ({ $config, $auth, redirect }) {
    if ($config.instance !== 'demo') {
      if (!$auth.location) {
        redirect('/auth/find/domain')
      }

      if (!$auth.accountIDs || $auth.accountIDs.length === 0) {
        redirect('/auth/login/email')
      }

      if ($auth.accountIDs && $auth.accountIDs.length > 0) {
        redirect('/auth/switch')
      }
    }
  },
  data () {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        businessType: null,
        skipSignUp: 0
      },
      demo: null,
      status: null,
      interval: null,
      ready: false,
      isMobile: null,
      industries: [
        {
          type: 'beauty',
          name: 'Hair & Beauty',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M157.73,113.13A8,8,0,0,1,159.82,102L227.48,55.7a8,8,0,0,1,9,13.21l-67.67,46.3a7.92,7.92,0,0,1-4.51,1.4A8,8,0,0,1,157.73,113.13Zm80.87,85.09a8,8,0,0,1-11.12,2.08L136,137.7,93.49,166.78a36,36,0,1,1-9-13.19L121.83,128,84.44,102.41a35.86,35.86,0,1,1,9-13.19l143,97.87A8,8,0,0,1,238.6,198.22ZM80,180a20,20,0,1,0-5.86,14.14A19.85,19.85,0,0,0,80,180ZM74.14,90.13a20,20,0,1,0-28.28,0A19.85,19.85,0,0,0,74.14,90.13Z"></path></svg>'
        },
        {
          type: 'spa',
          name: 'Spa & Massage',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M223.45,40.07a8,8,0,0,0-7.52-7.52C139.8,28.08,78.82,51,52.82,94a87.09,87.09,0,0,0-12.76,49c.57,15.92,5.21,32,13.79,47.85l-19.51,19.5a8,8,0,0,0,11.32,11.32l19.5-19.51C81,210.73,97.09,215.37,113,215.94q1.67.06,3.33.06A86.93,86.93,0,0,0,162,203.18C205,177.18,227.93,116.21,223.45,40.07ZM153.75,189.5c-22.75,13.78-49.68,14-76.71.77l88.63-88.62a8,8,0,0,0-11.32-11.32L65.73,179c-13.19-27-13-54,.77-76.71,22.09-36.47,74.6-56.44,141.31-54.06C210.2,114.89,190.22,167.41,153.75,189.5Z"></path></svg>'
        },
        {
          type: 'althealth',
          name: 'Allied Health',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M230.33,141.06a24.34,24.34,0,0,0-18.61-4.77C230.5,117.33,240,98.48,240,80c0-26.47-21.29-48-47.46-48A47.58,47.58,0,0,0,156,48.75,47.58,47.58,0,0,0,119.46,32C93.29,32,72,53.53,72,80c0,11,3.24,21.69,10.06,33a31.87,31.87,0,0,0-14.75,8.4L44.69,144H16A16,16,0,0,0,0,160v40a16,16,0,0,0,16,16H120a7.93,7.93,0,0,0,1.94-.24l64-16a6.94,6.94,0,0,0,1.19-.4L226,182.82l.44-.2a24.6,24.6,0,0,0,3.93-41.56ZM119.46,48A31.15,31.15,0,0,1,148.6,67a8,8,0,0,0,14.8,0,31.15,31.15,0,0,1,29.14-19C209.59,48,224,62.65,224,80c0,19.51-15.79,41.58-45.66,63.9l-11.09,2.55A28,28,0,0,0,140,112H100.68C92.05,100.36,88,90.12,88,80,88,62.65,102.41,48,119.46,48ZM16,160H40v40H16Zm203.43,8.21-38,16.18L119,200H56V155.31l22.63-22.62A15.86,15.86,0,0,1,89.94,128H140a12,12,0,0,1,0,24H112a8,8,0,0,0,0,16h32a8.32,8.32,0,0,0,1.79-.2l67-15.41.31-.08a8.6,8.6,0,0,1,6.3,15.9Z"></path></svg>'
        },
        {
          type: 'tattoo',
          name: 'Tattoo',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M92,104a28,28,0,1,0,28,28A28,28,0,0,0,92,104Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,92,144Zm72-40a28,28,0,1,0,28,28A28,28,0,0,0,164,104Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,164,144ZM128,16C70.65,16,24,60.86,24,116c0,34.1,18.27,66,48,84.28V216a16,16,0,0,0,16,16h80a16,16,0,0,0,16-16V200.28C213.73,182,232,150.1,232,116,232,60.86,185.35,16,128,16Zm44.12,172.69a8,8,0,0,0-4.12,7V216H152V192a8,8,0,0,0-16,0v24H120V192a8,8,0,0,0-16,0v24H88V195.69a8,8,0,0,0-4.12-7C56.81,173.69,40,145.84,40,116c0-46.32,39.48-84,88-84s88,37.68,88,84C216,145.83,199.19,173.69,172.12,188.69Z"></path></svg>'
        },
        {
          type: 'fitness',
          name: 'Fitness',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M164,80a28,28,0,1,0-28-28A28,28,0,0,0,164,80Zm0-40a12,12,0,1,1-12,12A12,12,0,0,1,164,40Zm36,96a40,40,0,1,0,40,40A40,40,0,0,0,200,136Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,200,200ZM56,136a40,40,0,1,0,40,40A40,40,0,0,0,56,136Zm0,64a24,24,0,1,1,24-24A24,24,0,0,1,56,200Zm136-80H152a8,8,0,0,1-5.66-2.34L120,91.31,99.31,112l34.35,34.34A8,8,0,0,1,136,152v48a8,8,0,0,1-16,0V155.31L82.34,117.66a8,8,0,0,1,0-11.32l32-32a8,8,0,0,1,11.32,0L155.31,104H192a8,8,0,0,1,0,16Z"></path></svg>'
        }
      ]
    }
  },
  computed: {
    isAndroid () {
      return this.isMobile?.android?.phone // || this.isMobile?.android?.tablet
    },
    isApple () {
      return this.isMobile?.apple?.phone // || this.isMobile?.apple.tablet
    },
    domain () {
      return this.$config.domain
    }
  },
  mounted () {
    this.isMobile = this.$mobile.isMobile()
    this.ready = true

    console.log(this.$route.query)

    if (this.$route.query?.type) {
      this.form.businessType = this.$route.query.type
    }

    if (this.$route.query?.skipSignUp) {
      this.form.skipSignUp = this.$route.query.skipSignUp === '1' ? 1 : 0
    }
  },
  methods: {
    /* async fetchDemoTypes () {
      try {
        const types = await API.Demo.types()
        this.types = types.items
      } catch (e) {
        this.$notification.error({ errors: e?.errors })
      }
    }, */
    async fetchDemoStatus () {
      try {
        const status = await this.demo.status()
        this.status = status

        if (status.isStatusTypeComplete) {
          clearInterval(this.interval)

          const token = status?.token
          const slug = status?.location?.slug
          const domain = this.domain

          const url = `${window.location.protocol}//${slug}.${domain}/auth/token?token=${token}`

          console.log(url)
          window.location.href = url
        }

        if (status.isStatusTypeError) {
          clearInterval(this.interval)

          this.demo = null
          this.status = null

          this.$notification.error()
        }
      } catch (e) {
        console.log(e)
        // this.$notification.error({ errors: e?.errors })
      }
    },
    async handleCreateDemo () {
      try {
        const form  = this.form
        const demo = await API.Demo.create({
          firstName: form.firstName,
          lastName: form.lastName,
          email: form.email,
          businessType: form.businessType,
          skipSignUp: form.skipSignUp
        })

        this.demo = demo

        if (demo) {
          this.interval = setInterval(() => {
            this.fetchDemoStatus()
          }, 2000)
        }
      } catch (e) {
        console.log(e)
        this.$notification.error({ errors: e?.errors })
      }
    }
  }
}
</script>
